<template>
  <div class="unbind setting world">
    <div class="back">
      <router-link to="./phone">返回</router-link>
    </div>
    <div class="form">
      <div class="tips" v-if="type == 1">
        <h3>{{ params.phone }}的手机号已绑定企业：{{ detail.companyName }}</h3>
        <p>
          您需先与{{ detail.companyName }}账号解绑，才能绑定其他企业账号。
        </p>
      </div>
      <div class="tips" v-else>
        <h3>{{ params.phone }}的手机号已注册为游客账号，您需先注销该游客账号，才可绑定其他企业账号</h3>
        <p>
          注：游客账号为未进行任何身份认证的账号，注销游客账号不会给您造成影响。
        </p>
      </div>
      <h3 class="title">
        {{ type == 1 ? `与${detail.companyName}解绑` : '注销游客账号' }}
      </h3>
      <div class="form_content" v-if="next == '0'">
        <div class="form_input">
          当前{{ type == 1 ? '绑定' : '注销' }}手机号：{{ params.phone }}
        </div>
        <div class="form_input">
          短信验证码：
          <el-input
              class="wrap_input"
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              @input="disabled = false"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <el-button
                  @click="sendMsg"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </div>
        <div class="form_input" v-if="type == 1">
          <el-button :type="!disabled?'primary':'info'" :disabled="disabled" class="btn" @click="getUnBindApi">下一步
          </el-button>
        </div>
        <div class="form_input" v-else>
          <el-button :type="!disabled?'primary':'info'" :disabled="disabled" class="btn" @click="getLogoffPhoneApi">确认注销</el-button>
        </div>
      </div>
      <div class="form_content" v-else>
        <div class="form_input">
          更改手机号：
          <el-input v-model.trim="form.iphone" class="wrap_input" placeholder="请输入用户名/手机号"></el-input>
        </div>
        <div class="form_input">
          短信验证码：
          <el-input
              class="wrap_input"
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
              @input="disabled = false"
          >
            <template slot="append">
              <el-button
                  @click="sendMsg"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </div>
        <div class="form_input">
          <el-button :type="!disabled?'primary':'info'" :disabled="disabled" class="btn">确认修改绑定手机号</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "unbind",
  data() {
    return {
      form: {
        phone: '',
        iphone: '',
        iphoneCode: '',
      },
      disabled: true,
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      next: '0',
      type: '1',
      detail: [],
      params: {
        phone: '',
        verificationCode: '',
        companyId: '',
      },
    }
  },
  methods: {
    //获取当前手机号所属公司
    async getCompany() {
      let res = await this.$api.getIphoneCompany({phone: this.params.phone});
      if (res.data.success) {
        this.detail = res.data.data;
      }
    },
    sendMsg() {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/phoneVerification/" +
              this.params.phone
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    //解绑
    getUnBindApi() {
      this.$api.getUnBindApi({
        bindPhone: this.params.phone,
        companyId: this.detail.id,
        verificationCode: this.form.iphoneCode
      }).then(res => {
        if (res.data.success) {
          this.$message.success('解绑成功!');
          this.$router.push('/enterprise/centre/setting/tips?type=1&phone=' + this.params.phone + '&name=' + this.detail.companyName+'&url='+this.$route.query.url);
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //  注销游客账号
    getLogoffPhoneApi() {
      this.$api.getLogoffPhoneApi({
        phone:this.params.phone,
        verificationCode:this.form.iphoneCode
      }).then(res =>{
        if (res.data.success) {
          this.$message.success('注销成功!');
          this.$router.push('/enterprise/centre/setting/tips?type=2&phone=' + this.params.phone);
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
  },
  created() {
    let query = this.$route.query;
    if (query.type) {
      this.type = query.type;
      this.params.phone = query.phone;
      this.getCompany();
    }

  }
}
</script>

<style scoped lang="less">
@import '~assets/css/setting.less';

.unbind {
  .back {
    font-size: 16px;
  }

  .form {
    width: 580px;
    margin: 0 auto;

    .tips {
      padding-top: 30px;

      p {
        color: #666666;
      }
    }

    .title {
      margin-top: 30px;
    }

    .form_input {
      display: flex;
      font-size: 16px;
      margin-bottom: 20px;
      align-items: center;

      /deep/ .wrap_input {
        width: 280px;
        border: 1px solid #DCDFE6;
        border-radius: 6px;

        .el-input__inner {
          border: none;
          background: rgba(0, 0, 0, 0.03);
        }

        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99A09D;
        }

        .el-input-group--append {
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }

        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716C;
            padding-left: 5px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #28A46D;
          }
        }
      }
    }

    .form_input:last-child {
      .btn {
        width: 280px;
        margin-top: 30px;
        margin-left: 95px;
      }
    }
  }
}
</style>